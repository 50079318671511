import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'
import truncate from 'truncate-html'

import Layout from '../components/layout'
import ProductGallery from '../components/product/gallery'
import ProductDetails from '../components/product/details'
import ProductTabs from '../components/product/tabs'
import SEO from '../components/seo'
import ManufacturerBadge from '../components/manufacturer-badge'

const ProductTemplate = ({ data }) => {
	const {
		title,
		sku,
		manufacturer,
		categories,
		longDescription,
		shortDescription,
		tabs,
		image,
		gallery,
		files,
	} = data.product

	const images = []

	if (image) {
		images.push(image)
	}

	if (gallery) {
		images.push(...gallery)
	}

	return (
		<Layout>
			<SEO
				title={title}
				description={truncate(shortDescription, 128, {
					stripTags: true,
					excludes: ['ul'],
				})}
			/>
			<div
				id="product-single"
				className="uk-card uk-card-default uk-card-small uk-card-body"
				data-uk-grid
			>
				{manufacturer && <ManufacturerBadge manufacturer={manufacturer} />}

				<ProductGallery images={images || []} />
				<ProductDetails
					title={title}
					sku={sku}
					categories={categories}
					shortDescription={shortDescription}
				/>
				<ProductTabs
					description={longDescription}
					tabs={tabs || []}
					files={files || []}
				/>
			</div>
		</Layout>
	)
}

export default ProductTemplate

ProductTemplate.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
	query ProductData($id: String!) {
		product: sanityProduct(id: { eq: $id }) {
			slug {
				current
			}
			title
			sku
			shortDescription
			longDescription
			manufacturer {
				slug {
					current
				}
				title
				color {
					hex
				}
			}
			categories {
				slug {
					current
				}
				title
			}
			tabs {
				title
				content
			}
			image {
				asset {
					gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
				}
			}
			gallery {
				asset {
					gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
				}
			}
			files {
				asset {
					originalFilename
					url
				}
			}
		}
	}
`
export const productCardDataFragment = graphql`
	fragment ProductCardDataFragment on SanityProduct {
		title
		slug {
			current
		}
		sku
		manufacturer {
			slug {
				current
			}
			title
			color {
				hex
			}
		}
		shortDescription
		image {
			asset {
				gatsbyImageData(width: 256, layout: FIXED, placeholder: BLURRED)
			}
		}
	}
`
