import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { getCategoryURL, getSlug } from '../../utils/url'

const ProductDetails = ({ title, sku, shortDescription, categories }) => {
	// todo: dar um jeito de procurar nas categorias filho
	let isFirstCategory = true
	const categoriesEl = categories.map((category, index) => {
		if (isFirstCategory) {
			isFirstCategory = false
			return (
				<Link key={index} to={getCategoryURL(getSlug(category))}>
					{category.title}
				</Link>
			)
		}
		return (
			<Link key={index} to={getCategoryURL(getSlug(category))}>
				, {category.title}
			</Link>
		)
	})

	return (
		<div className="uk-width-1-1@s uk-width-2-3@m">
			<h3 className="uk-heading-divider">{title}</h3>
			<div className="short_description">
				<p dangerouslySetInnerHTML={{ __html: shortDescription }}></p>
			</div>
			<div className="uk-text-meta">
				<span className="uk-display-block">
					<strong>REF:</strong> {sku || 'N/A'}
				</span>
				{categories && (
					<span className="uk-display-block">
						<strong>Categorias: </strong>
						{categoriesEl}
					</span>
				)}
			</div>
			<div className="uk-flex uk-flex-center uk-flex-right@m uk-margin-xlarge-top">
				<button
					className="uk-button uk-button-primary"
					uk-toggle="target: #contact-modal"
				>
					<Link
						to={`/contato/`}
						state={{ targetProduct: { title, sku, quantity: 1 } }}
						className="uk-link-reset"
					>
						Solicite um orçamento
					</Link>
				</button>
			</div>
		</div>
	)
}

ProductDetails.propTypes = {
	title: PropTypes.string.isRequired,
	sku: PropTypes.string,
	shortDescription: PropTypes.string.isRequired,
	categories: PropTypes.arrayOf(PropTypes.object),
}

export default ProductDetails
