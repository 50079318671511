import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'

const UIKit = require('uikit')

const ProductGallery = ({ images }) => {
	const lightboxRef = React.createRef()
	const [primaryImage, setPrimaryImage] = useState(0)

	function setGalleryPrimary(index, e) {
		e.preventDefault()
		setPrimaryImage(index)
	}

	function openLightbox() {
		UIKit.lightbox(lightboxRef.current).show(primaryImage)
	}

	return (
		<div className="uk-width-1-1@s uk-width-1-3@m uk-margin-large-top">
			<div onClick={() => openLightbox()}>
				<GatsbyImage
					image={
						images[primaryImage] ? getImage(images[primaryImage].asset) : null
					}
					imgStyle={{ cursor: 'zoom-in', objectFit: 'contain' }}
					alt="Imagem Principal"
				/>
			</div>

			<div uk-slider="finite: true">
				<div
					className="uk-position-relative uk-visible-toggle uk-dark"
					tabIndex="-1"
				>
					<ul className="uk-slider-items uk-child-width-1-3@s uk-child-width-1-4@m">
						{images.map((image, index) => (
							<li key={index}>
								<div
									onClick={setGalleryPrimary.bind(this, index)}
									style={{ width: 128, height: 128, cursor: 'pointer' }}
									className="uk-inline"
								>
									<GatsbyImage
										image={getImage(image.asset)}
										alt={`Imagem do produto (${index + 1})`}
										className="uk-padding-small"
										imgStyle={{ objectFit: 'contain' }}
									/>
								</div>
							</li>
						))}
					</ul>

					<a
						className="uk-position-center-left uk-position-small uk-hidden-hover"
						href="#"
						data-uk-slidenav-previous
						uk-slider-item="previous"
					></a>
					<a
						className="uk-position-center-right uk-position-small uk-hidden-hover"
						href="#"
						data-uk-slidenav-next
						uk-slider-item="next"
					></a>
				</div>
			</div>

			<div id="lightbox" ref={lightboxRef} data-uk-lightbox>
				{images.map((image, index) => (
					<a
						key={`link-${index}`}
						href={getSrc(image.asset.gatsbyImageData)}
						onClick={setGalleryPrimary.bind(this, index)}
					></a>
				))}
			</div>
		</div>
	)
}

ProductGallery.propTypes = {
	images: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ProductGallery
