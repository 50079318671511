import PropTypes from 'prop-types'
import React from 'react'
import { getManufacturerURL, getSlug } from '../utils/url'
import { Link } from 'gatsby'

const ManufacturerBadge = ({ manufacturer }) => (
	<div
		className="uk-card-badge uk-label"
		style={{ backgroundColor: manufacturer.color.hex }}
	>
		<Link
			to={getManufacturerURL(getSlug(manufacturer))}
			className="uk-link-reset"
		>
			{manufacturer.title}
		</Link>
	</div>
)

ManufacturerBadge.propTypes = {
	manufacturer: PropTypes.object.isRequired,
}

export default ManufacturerBadge
