import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { getFileURL } from '../../utils/url'

const ProductTabs = ({ description, tabs, files }) => {
	useEffect(() => {
		if (typeof document !== 'undefined') {
			try {
				const wrapper = document.querySelector('#content-tabs')

				if (!wrapper) {
					return
				}

				const tables = wrapper.querySelectorAll('table')

				tables.forEach(function (table) {
					table.className = 'uk-table uk-table-striped uk-table-responsive'
				})
			} catch (e) {
				console.error(e)
			}
		}
	}, [])

	const renderFiles = files && files.length > 0

	return (
		<div className="uk-width-1-1">
			<ul uk-tab="animation: uk-animation-fade">
				<li key="description-title" className="uk-active">
					<a>Descrição</a>
				</li>
				{tabs.map((tab) => (
					<li key={`${tab.title}-title`}>
						<a>{tab.title}</a>
					</li>
				))}

				{renderFiles && (
					<li key="files-title">
						<a>Downloads</a>
					</li>
				)}
			</ul>

			<ul id="content-tabs" className="uk-switcher uk-margin">
				<li
					key="description-title"
					className="uk-active"
					dangerouslySetInnerHTML={{ __html: description }}
				></li>
				{tabs.map((tab) => (
					<li
						key={`${tab.title}-content`}
						dangerouslySetInnerHTML={{ __html: tab.content }}
					></li>
				))}

				{renderFiles && (
					<li key="files-title">
						<ul>
							{files.map((file, index) => (
								<li key={`file-${index}`}>
									<a
										href={file.asset.url}
										target="_blank"
										rel="noopener noreferrer"
									>
										{file.asset.originalFilename}
									</a>
								</li>
							))}
						</ul>
					</li>
				)}
			</ul>
		</div>
	)
}

ProductTabs.propTypes = {
	description: PropTypes.string.isRequired,
	tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
	files: PropTypes.arrayOf(PropTypes.object),
}

export default ProductTabs
